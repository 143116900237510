
























import { Component, Vue } from 'vue-property-decorator'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import { CakeFilling } from '@/shared/models/fillings'
import Loader from '@/components/common/Loader.vue'

@Component({
  components: {
    WidgetTitle,
    Loader
  }
})
export default class Fillings extends Vue {
  isLoading = true

  get fillings(): Array<CakeFilling> {
    return this.$store.getters.fillings
  }

  mounted(): void {
    if (this.fillings.length) {
      this.isLoading = false
    } else {
      this.$store.dispatch('getFillings').finally(() => {
        this.isLoading = false
      })
    }
  }
}
